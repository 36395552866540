<template>
  <div id="graphics-icons" class="graphics-wrap color-overlay-icons">
    <div
      class="item"
      v-for="(componentName, index) in arrowComponents"
      @click="addELToCanvas(index)"
      :ref="'svg' + index"
      :key="index"
    >
      <component
        :class="'svg' + index"
        :is="`arrow${componentName}`"
      ></component>
    </div>
  </div>
</template>

<script>
import { GRAPHICS_MIXIN } from "../graphics.mixins";

export default {
  mixins: [GRAPHICS_MIXIN],
  computed: {
    arrowComponents: function() {
      return Array.from(Array(24).keys());
    },
  },
  created() {
    // https://stackoverflow.com/a/58081641/1247864
    for (let c = 0; c < this.arrowComponents.length; c++) {
      let componentName = this.arrowComponents[c];

      this.$options.components[`arrow${componentName}`] = () =>
        import("../assets/i/arrows/arrow" + componentName + ".vue");
    }
  },
  methods: {
    addELToCanvas(index) {
      this.$_graphicsMixin_addSVGToCanvas(index);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../index.component.scss";
</style>
